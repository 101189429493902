var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",attrs:{"id":"shipping-details-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:2","name":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"firstname","label":_vm.$t('First Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:2","name":"lastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"lastname","label":_vm.$t('Last Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)})],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required","name":"street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"street","label":_vm.$t('Street Name'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.street),callback:function ($$v) {_vm.$set(_vm.form, "street", $$v)},expression:"form.street"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required","name":"apartment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"apartment","label":_vm.$t('House/Apartment number'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.apartment),callback:function ($$v) {_vm.$set(_vm.form, "apartment", $$v)},expression:"form.apartment"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"name":"country_code","rules":"required|min:2","slim":""}},[_c('vSelect',{attrs:{"options":_vm.countriesList,"reduce":function (x) { return x.abbreviation; },"clearable":false},on:{"input":function($event){return _vm.updateCountry({ id: $event })}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"vs__label"},[_vm._v("\n              "+_vm._s(_vm.$t("Country") + " *")+"\n            ")])]},proxy:true},{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.form.country_code}},'input',attributes,false),events))]}}],null,true),model:{value:(_vm.form.country_code),callback:function ($$v) {_vm.$set(_vm.form, "country_code", $$v)},expression:"form.country_code"}})],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":!_vm.form.country_code || _vm.cityList.length === 0
            ? null
            : 'required|min:2'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(!_vm.form.country_code || _vm.cityList.length === 0)?_c('SfInput',{attrs:{"label":_vm.$t('City'),"name":"city","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":!_vm.form.country_code || _vm.loading},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}):_c('vSelect',{attrs:{"options":_vm.cityList,"reduce":function (x) { return x.label; },"clearable":false},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"vs__label"},[_vm._v("\n              "+_vm._s(_vm.$t("City") + " *")+"\n            ")])]},proxy:true},{key:"search",fn:function(ref){
            var attributes = ref.attributes;
            var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.form.city}},'input',attributes,false),events))]}}],null,true),model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__horizontal"},[_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":"required|min:4","name":"postcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"postcode","label":_vm.$t('Zip-code'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", $$v)},expression:"form.postcode"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{staticClass:"form__element",attrs:{"rules":{ regex: /^[0-9]*$/ },"name":"telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('SfInput',{attrs:{"name":"telephone","placeholder":_vm.$t('Phone number'),"required":"","valid":!errors[0],"error-message":_vm.$t(errors[0]),"disabled":_vm.loading},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}})]}}],null,true)})],1),_vm._v(" "),_c('SfCheckbox',{staticClass:"form__checkbox-isDefaultShipping",attrs:{"name":"isDefaultShipping","label":_vm.$t('Set as default shipping'),"disabled":_vm.loading || _vm.disableShippingCheckbox},model:{value:(_vm.form.default_shipping),callback:function ($$v) {_vm.$set(_vm.form, "default_shipping", $$v)},expression:"form.default_shipping"}}),_vm._v(" "),_c('SfCheckbox',{staticClass:"form__checkbox-isDefaultBilling",attrs:{"name":"isDefaultBilling","label":_vm.$t('Set as default billing'),"disabled":_vm.loading},model:{value:(_vm.form.default_billing),callback:function ($$v) {_vm.$set(_vm.form, "default_billing", $$v)},expression:"form.default_billing"}}),_vm._v(" "),_c('SfButton',{staticClass:"form__button",attrs:{"type":"submit","disabled":_vm.loading}},[_vm._t("submit-button-content")],2)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }