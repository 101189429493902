
export default `
query shippingCountriesList {
  shippingCountries {
    id
    two_letter_abbreviation
    full_name_locale
    full_name_english

    available_cities {
      id
      code
      name
    }
  }
}
`;