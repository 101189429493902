











































































































































































































import { SfInput, SfButton, SfSelect, SfCheckbox } from "@storefront-ui/vue";
import { required, min, oneOf, regex } from "vee-validate/dist/rules";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  ref,
  watch,
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
} from "@nuxtjs/composition-api";
import omitDeep from "omit-deep";
import { PropType } from "vue";
import { CountryCodeEnum, useConfig } from "~/composables";
import useUserPhoneCode from "~/modules/hudson/composables/useUserPhoneCode";
import type { Countries, Country, UseCountrySearchParams } from "~/composables";
import addressGetter from "~/modules/customer/getters/addressGetter";
import type { TransformedCustomerAddress } from "~/modules/customer/composables/types";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useCountrySearch } from "~/modules/hudson/composables/useCountrySearch";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("min", {
  ...min,
  message: "The field should have at least {length} characters",
});

extend("oneOf", {
  ...oneOf,
  message: "Invalid country",
});
extend("regex", {
  ...regex,
  message: "Please provide a valid phone number",
});
export default defineComponent({
  name: "AddressForm",

  components: {
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  props: {
    address: {
      type: Object as PropType<TransformedCustomerAddress>,
      default: () => ({
        apartment: "",
        city: "",
        country_code: "MT" as CountryCodeEnum,
        firstname: "",
        lastname: "",
        postcode: "",
        region: {
          region_code: "",
          region_id: 0,
        },
        street: "",
        telephone: "",
        default_shipping: false,
        default_billing: false,
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { load: loadCountries, search: searchCountry } = useCountrySearch();
    const {
      initPhoneInput,
      getPhoneInputDisplay,
      getFullPhoneNumber,
      setFullPhoneNumber,
      registerPhoneCountryChangeEvent,
      unregisterPhoneCountryChangeEvent,
    } = useUserPhoneCode();

    let iti;
    let phoneCodeInput;

    const form = ref<TransformedCustomerAddress | null>(null);

    const countries = ref<Countries[]>([]);
    const countriesList = computed(() =>
      addressGetter.countriesList(countries.value)
    );
    const disableShippingCheckbox = ref<Boolean>(false);
    const { config } = useConfig();
    const country = ref<Country | null>(null);
    let allowedShippingCountries = null;
    if (config.value?.general_country_allow_for_shipping) {
      allowedShippingCountries =
        config.value?.general_country_allow_for_shipping.split(",");
    }
    const regionInformation = computed(() =>
      addressGetter.regionList(country.value)
    );

    const cityList = computed(() => addressGetter.cityList(country.value));

    const updateCountry = async (params: UseCountrySearchParams) => {
      country.value = await searchCountry(params);
      form.value.region = {
        // let region SfSelect know it should display initial state
        ...(regionInformation.value.length > 0 ? { region_id: null } : {}),
      };

      form.value.city = "";

      toggleDefaultShippingCheckbox(country.value?.id);
    };

    //Set Default-Shipping checkbox disabled if country selected not from the allowed shipping countries
    const toggleDefaultShippingCheckbox = (iCountryId) => {
      if (allowedShippingCountries.indexOf(iCountryId) === -1) {
        disableShippingCheckbox.value = true;
        form.value.default_shipping = false;
      } else {
        disableShippingCheckbox.value = false;
      }
    };

    watch(
      () => props.address,
      () => {
        form.value = {
          apartment: props.address.apartment,
          city: props.address.city,
          country_code: props.address.country_code,
          firstname: props.address.firstname,
          lastname: props.address.lastname,
          postcode: props.address.postcode,
          region: {
            region_code: "",
            region_id: null,
            ...props.address.region,
          },
          street: props.address.street,
          telephone: props.address.telephone,
          default_shipping: props.address.default_shipping || false,
          default_billing: props.address.default_billing || false,
        } as TransformedCustomerAddress;
      },
      { immediate: true }
    );

    const submitForm = () => {
      const regionId = regionInformation.value.find(
        (r) => r.abbreviation === form.value.region.region_code
      )?.id;
      if (regionId) {
        form.value.region.region_id = regionId;
      }

      form.value = {
        ...form.value,
        telephone: getFullPhoneNumber(form.value.telephone, iti),
      };

      emit("submit", {
        form: omitDeep(form.value, ["__typename"]),
        // TODO: Handle Error
        onError: () => {},
      });
    };

    onMounted(async () => {
      countries.value = await loadCountries();
      if (props.address.country_code) {
        country.value = await searchCountry({ id: props.address.country_code });
        toggleDefaultShippingCheckbox(country.value?.id);
      }

      phoneCodeInput = document.querySelector("#telephone") as HTMLInputElement;
      iti = initPhoneInput(phoneCodeInput);
      const { formattedPhoneInput, suffix } = getPhoneInputDisplay(
        form.value.telephone
      );

      form.value.telephone = suffix;
      iti && setFullPhoneNumber(formattedPhoneInput, iti);
      iti && registerPhoneCountryChangeEvent(phoneCodeInput, iti);
    });

    onUnmounted(() => {
      iti &&
        phoneCodeInput &&
        unregisterPhoneCountryChangeEvent(phoneCodeInput, iti);
    });

    return {
      form,
      submitForm,
      countriesList,
      regionInformation,
      cityList,
      updateCountry,
      disableShippingCheckbox,
    };
  },
});
