import type { UseUserPhoneCodeInterface } from './useUserPhoneCode';

import axios from "axios";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/utils.js";

export function useUserPhoneCode(): UseUserPhoneCodeInterface {
  const DEFAULT_PHONE_PREFIX = "356";
  const GEO_LOOKUP_URL = "https://ipinfo.io";
  const DEFAULT_COUNTRY_ISO2 = "mt";

  let fullPhoneCode = "";

  const initPhoneInput = (formInputElement: HTMLInputElement) => {
    return !!formInputElement &&
      intlTelInput(formInputElement, {
        initialCountry: DEFAULT_COUNTRY_ISO2,
        separateDialCode: true,
        placeholderNumberType: "off",
        preferredCountries: [DEFAULT_COUNTRY_ISO2],
        geoIpLookup: (success) => {
          axios.get(GEO_LOOKUP_URL).then((resp: any) => {
            const countryCode = resp && resp.country ? resp.country : DEFAULT_COUNTRY_ISO2;
            success(countryCode);
          });
        }
      });
  };

  const getFullPhoneNumber = (formInputValue: string, iti: any) => {
    if (iti === false) {
      return getPayloadPrefix(fullPhoneCode, "") + formInputValue;
    }

    fullPhoneCode = iti?.getSelectedCountryData().dialCode || DEFAULT_PHONE_PREFIX;

    return getPayloadPrefix(fullPhoneCode, "") + fullPhoneCode + formInputValue;
  }

  const setFullPhoneNumber = async (formInputValue: string, iti: any) => {
    if (!formInputValue) return;

    iti?.setNumber(formInputValue);
  };

  const getPhoneInputDisplay = (formInputValue: string) => {
    const { prefix, suffix } = formatPhoneNumber(formInputValue);
    const formattedPhoneInput = prefix + suffix;

    return { formattedPhoneInput, suffix };
  };

  const formatPhoneNumber = (formInputValue: string) => {
    let prefix = formInputValue.substring(0, 3) ?? "";
    const suffix = formInputValue.substring(3) ?? "";

    (prefix?.indexOf("00") === 0)
      ? prefix = prefix.replace("00", "+")
      : (prefix?.indexOf("0") === 0)
        ? prefix = prefix.replace("0", "+")
        : prefix = "+" + prefix;

    return { prefix, suffix };
  }

  const registerPhoneCountryChangeEvent = (formInputElement: HTMLInputElement, iti: any, callback?: any) => {
    formInputElement?.addEventListener("countrychange", () => {
      const phoneCodeDialValue = iti?.getSelectedCountryData().dialCode;
      const prefix = getPayloadPrefix(phoneCodeDialValue, "+");

      fullPhoneCode = prefix + phoneCodeDialValue;
      !!callback && callback();
    });
  };

  const getPayloadPrefix = (phoneCodeDialValue: string, defaultValue?: string) => {
    const { length } = phoneCodeDialValue;

    switch (length) {
      case 1:
        return "00";
      case 2:
        return "0";
      case 3:
        return "";
      default:
        return defaultValue || "";
    }
  }

  const unregisterPhoneCountryChangeEvent = (formInputElement: HTMLInputElement, iti: any) => {
    formInputElement?.removeEventListener("countrychange", () => {
      iti?.destroy();
    });
  };

  return {
    initPhoneInput,
    getPhoneInputDisplay,
    getFullPhoneNumber,
    setFullPhoneNumber,
    registerPhoneCountryChangeEvent,
    unregisterPhoneCountryChangeEvent,
  };
}

export default useUserPhoneCode;
export * from './useUserPhoneCode';
